html {
    padding:0;
    margin:0;
    height:100%;
}

body {
    font-family: futura-pt, sans-serif;
    padding:0;
    margin:0;
    background:url(img/window.jpg) no-repeat 220% center;
    background:#fff;
    background-size:auto 100vh;
    width:100vw;
    background:#F3FCFF;

}

#over-1 {
    position:absolute;
    width:200vw;
    height:100vh;
    background:#224281;
    z-index:-3;
    left:-150vw;
    top:0;
    transform:skew(-15deg);
    overflow:hidden;
    &:before {
        width:100vw;
        height:100vh;
        background:url(img/window.jpg) no-repeat left center #224281;
        content:"";
        display:block;
        position:absolute;
        background-size:auto 100vh;
        transform:skew(15deg);
        right:-30vw;
        opacity:1;
        background-blend-mode: multiply;
    }
}

#over-2 {
    position:absolute;
    width:200vw;
    height:100vh;
    background:#224281;
    opacity:.5;
    z-index:-2;
    left:-148vw;
    top:0;
    transform:skew(-17deg);
    background-blend-mode: multiply;
}

.container {
    display:flex;
    min-height:100vh;
    align-items: center;
    justify-content: center;
    > div {
        background:#fff;
        box-shadow:0 0 100px rgba(10,30,70,.3);
        padding:2em;
        border-radius:4px;
        overflow:hidden;
        margin:10px;
        position:relative;
        .skew {
            display:none;
        }
        @media screen and (min-width:45em){     
            .skew{
                content:"";
                position:absolute;
                width:100%;
                height:110%;
                margin-top:-2%;
                left:50%;
                transform:skew(-15deg);
                background:#224281;
                top:0;
                display:block;
                overflow:hidden;
                &:before {
                    width:4vw;
                    margin-left:-2vw;
                    background:#2ecc71;
                    height:100%;
                    display:block;
                    content:"";
                    transform:skew(-2deg);
                }
            }
        }
   
    }
    .row {
        display:grid;
        position:relative;
        @media screen and (min-width:45em){
            grid-template-columns: 1fr .5fr 1fr;
        }
        
    }
    .left {
        text-align:left;
        img {
            max-width:300px;
        }
        .tagline {
            text-transform: uppercase;
            max-width:300px;
            color:#225099;
            margin:0 auto;
            margin-top:-1.2em;
            margin-bottom:1em;
            font-size:1.3em;
        }
        .mail,.tel {
            font-size:1.5em;
            margin:.2em 1em;
            color:#225099;
            white-space: nowrap;
            text-align:center;
            &:before {
                width:.8em;
                height:.8em;
                vertical-align: middle;
                content:"";
                margin-top:-.3em;
                margin-right:.5em;
                display:inline-block;
                background-size: contain;
                margin-left:-.5em;
            }
        }
        .mail:before {
            background:url(img/phone.svg);
        }
        .tel:before {
            background:url(img/mail.svg);
        }

        p {
            color:#8393b4;
            margin: 2em 1em;
            font-size:.9em;
            box-sizing: border-box;
            text-align: center;
        }
    }
    .right {
        max-width:20em;
        text-align:right;
        display:flex;
        position:relative;
        h1 {
            font-size:3em;
            color:#F3FCFF;
            bottom:0;
            margin:0;
            position:relative;
            align-self: flex-end;
            @media screen and (max-width:45em){
                
                margin:0 -2rem -2rem -2rem;
                border-top:1rem solid #2ecc71;
                padding: 1rem;
                text-align:center;
                background:#224281;
                font-size:2.4em;
                align-self: flex-start;
            }
        }
    }
}